<template>
  <div class="login h-100 w-100 overflow-hidden">
    <div class="row h-100 overflow-auto">
      <div class="col-12 col-md-4 offset-md-6 d-flex">
        <div class="login-card pt-md-5 mt-md-5 p-3 w-100 d-flex flex-column">
          <img
            src="@/assets/pocketyard_logo_black.svg"
            alt="logo"
            class="login-logo ml-auto mr-auto"
          />
          <!-- Login Card -->
          <div v-if="!forgotPassword" class="mt-md-4 h-100 d-flex flex-column">
            <AppInputForm
              v-model="formValid"
              v-slot="{ validate }"
              class="d-flex flex-column mt-auto mb-auto"
              @validate="authenticateForm"
            >
              <h2 class="text-uppercase text-center">Sign In</h2>

              <!-- Username -->
              <AppInput
                v-model="username.value"
                name="username"
                label="EMAIL ADDRESS"
                class="mt-md-4"
                :validate="validate"
                :validations="username.validations"
              ></AppInput>

              <!-- Forgot Password -->
              <AppBtn
                text
                class="
                  login-forgot-password
                  ml-auto
                  mb-n5
                  p-0
                  pb-1
                  d-block
                  text-right
                "
                :disabled="loading"
                @click="forgotPassword = true"
              >
                Forgot Password?
              </AppBtn>
              <!-- Password -->
              <AppInput
                v-model="password.value"
                type="password"
                name="password"
                label="password"
                class="mt-4"
                autocomplete="on"
                :validate="validate"
                :validations="password.validations"
              ></AppInput>

              <AppInputCheckbox
                v-model="persistAccount"
                label="Stay signed in"
              ></AppInputCheckbox>
              <AppBtn type="submit" :loading="loading">Sign In</AppBtn>
              <p v-if="innerError" class="text-danger mt-1">{{ innerError }}</p>
            </AppInputForm>

            <p class="login-no-account text-center w-100 mt-auto">
              <span>Don’t have an account?</span>
              <router-link :to="{ name: 'Landing' }" class="ml-2">Get in touch</router-link>
            </p>
          </div>

          <!-- Forgot Password Card -->
          <div v-else class="mt-md-4">
            <h2>FORGOT PASSWORD</h2>
            <p>
              Enter the email address associated with your account and we’ll
              send you a link to reset your password.
            </p>
            <AppInputForm
              v-model="resetFormValid"
              v-slot="{ validate }"
              @validate="requestNewPassword"
            >
              <AppInput
                v-model="username.value"
                label="Email"
                class="mt-5"
                :validate="validate"
                :validations="username.recoveryValidation"
              ></AppInput>
              <p v-if="innerError" class="text-danger m-0">{{ innerError }}</p>
              <AppBtn
                type="submit"
                class="mt-3 w-100 text-uppercase"
                :prependIcon="requestPasswordSent ? 'checkmark' : ''"
                :color="
                  requestPasswordSent ? 'info-light-5 text-info' : 'primary'
                "
                :disabled="!username.value || requestPasswordSent"
                :loading="loading"
              >
                {{
                  requestPasswordSent
                    ? 'recovery link sent to email'
                    : 'Send Recovery Email'
                }}
              </AppBtn>
            </AppInputForm>
            <AppBtn
              text
              class="login-no-account mb-5 w-100 text-black"
              @click="forgotPassword = false"
            >
              Back to login
            </AppBtn>
          </div>
        </div>
      </div>
    </div>
    <!-- Reset Password Success -->
    <SuccessModal v-model="requestPasswordSent" @confirm="$router.go()">
      <template v-slot:title>Password Reset Request Sent</template>
      <template v-slot:description>
        Please check your email for the link to the password reset email
      </template>
    </SuccessModal>
  </div>
</template>

<script>
import AppInputForm from '@/shared/elements/AppInputForm.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import AppInputCheckbox from '@/shared/elements/AppInputCheckbox.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import { required, email } from '@/shared/utils/validation';
import Authentication from '@/shared/api/Authentication';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import SuccessModal from '../../components/Partial/Modals/SuccessModal.vue';
export default {
  metaInfo: {
    title: 'Login',
  },

  name: 'Login',

  mixins: [_appApiHelper],

  components: {
    AppInput,
    AppInputCheckbox,
    AppBtn,
    AppInputForm,
    SuccessModal,
  },

  data() {
    return {
      persistAccount: false,
      innerError: false,
      forgotPassword: false,
      requestPasswordSent: false,
      resetFormValid: false,
      formValid: false,
      username: {
        value: '',
        validations: [required()],
        recoveryValidation: [required(), email()],
      },
      password: {
        value: '',
        validations: [required()],
      },
    };
  },

  watch: {
    forgotPassword() {
      this.innerError = false;
      this.requestPasswordSent = false;
      this.resetFormValid = false;
    },
  },

  methods: {
    authenticateForm() {
      if (!this.formValid) {
        return;
      }
      this.$store.dispatch('api/preventSuccessModal', true);
      this.$store.dispatch('auth/login', {
        // credentials
        data: {
          username: this.username.value,
          password: this.password.value,
        },
        persistTill: this.persistAccount ? '7d' : null,
        // login success
        onSuccess: () => {
          this.$router.push({ name: 'Dashboard' });
        },
        onError: () => {
          this.innerError = this.$store.getters['api/error'];
        },
      });
    },
    async requestNewPassword() {
      if (!this.resetFormValid) {
        return;
      }
      this.$store.dispatch('api/setModal', {
        header: 'SUBMITTED!',
        description:
          'Your request for password reset has been submitted to your email! Kindly open the link attached to reset your password',
      });
      const response = await Authentication.forgotPassword(this.username.value);
      this.requestPasswordSent = response.status === 200;
      this.innerError =
        response.status === 200 ? false : this.$store.getters['api/error'];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';

.login {
  background-image: url('~@/assets/Login/background.png');
  background-position: right center;
  background-size: cover;

  &-logo {
    width: 25%;
    @include media-md('below') {
      width: 50%;
    }
  }

  &-forgot-password {
    position: relative;
    z-index: 999;
  }
}
</style>
